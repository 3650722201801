.layout-header-name {
    padding-left: 20px;
    font-size: 23px;
    color: #000;
    text-align: center;
    line-height: 64px;
}
.layout-container.active .layout-header-name {
    display: inline-block;
}
.layout-header-right-container {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}
.layout-header-right-container .user-profile {
    height: 64px;
    line-height: 64px;
}
.layout-container.active .user-profile .avatar {
    display: inline-block;
}
.layout-header-right-container .user-profile .avatar {
    display: inline-block;
    background-color: #EA4335;
    color: #fff;
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 0;
    font-size: 26px;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
}
.layout-header-right-container .user-profile .user-info {
    font-size: 26px;
    line-height: 64px;
    text-align: center;
    padding-left: 10px;
    color: #707070;
}
.layout-header-right-container .logout {
    display: block;
    position: relative;
    right: 0;
    background-color: inherit;
    border: 0;
    outline: none;
    cursor: pointer;
    margin: 0 10px;
}
.layout-header-right-container .logout i {
    width: 45px;
    height: 45px;
    text-align: center;
    line-height: 45px;
    font-size: 23px;
    color: #000;
}