.logo-layout {
    padding-bottom: 20px;
}
.logo-layout img {
    width: 45px;
    height: 45px;
    margin-right: 5px;
    overflow: hidden;
}
.logo-layout a {
    display: inline-flex;
    text-decoration: none;
    color: #fff;
    overflow: hidden;
    font-size: 30px;
    align-items: center;
    text-decoration: none;
    text-transform: uppercase;
    cursor: pointer;
    opacity: 1;
    overflow: hidden;
    color: #fff;
    text-align: center;
    align-items: center;
}
.sign-layout {
    font-family: sans-serif;
    background-color: #04A9F5;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
}
.container {
    background: #fff;
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    position: relative;
    width: 360px;
    height: 480px;
    max-width: 96%;
    max-height: 100%;
}
.container .form-container{
    position: relative;
    transition: all .6s ease-in-out;
}
.form-container .form-layout {
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px 30px;
    height: 100%;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.form-container .title {
    font-size: 30px;
    font-weight: 400;
    color: #333;
    margin-bottom: 10px;
}
.form-container .input-field {
    position: relative;
    max-width: 380px;
    width: 100%;
    height: 55px;
    background-color: #eee;
    display: flex;
    flex-direction: row;
    margin: 6px 0;
}
.form-container .input-field .input-label,
.form-container .input-field .input-password {
    display: block;
    background: transparent;
    border: none;
    outline: none;
    position: absolute;
    text-align: center;
    color: #acacac;
    padding: 10px 15px;
    z-index: 10;
    transform: translateY(-50%);
    top: 50%;
}
.input-label {
    left: 0;
}
.input-password {
    border-radius: 50%;
    cursor: pointer;
    right: 0;
}
.input-alert {
    display: block;
    font-size: 16px;
    text-align: left;
    left: 0;
    width: 100%;
    overflow: hidden;
}
.input-alert i {
    margin-right: 10px;
}
.input-alert-error {
    color: #ef5350;
}
.input-alert-success {
    color: #4caf50;
}
.form-layout a{
    color: #333;
    font-size: 14px;
    text-decoration: none;
    margin: 15px 0;
}
.input-field input {
    position: relative;
    padding: 10px 10px 10px 40px;
    background: #eee;
    border: none;
    outline: none;
    width: 100%;
    line-height: 1;
    font-weight: 600;
    font-size: 18px;
    font-family: 'sans-serif';
    color: #333;
    border: 1px solid transparent;
}
.input-field.password input {
    padding: 10px 40px 10px 40px;
}
.input-field input::placeholder {
    color: #aaa;
    font-weight: 500;
}
.input-field input:focus{
    border: 1px solid #2962FF;
}
.input-checkbox {
    display: flex;
    margin: 10px 0;
    width: 100%;
    font-size: 12px;
}
.input-checkbox input{
    margin-right: 5px;
}
.input-checkbox label {
    color: #777;
}
.captcha-box {
    width: 100%;
    /* padding: 10px 5px; */
    margin: 8px 0;
    background: #fff;
    /* border: 1px solid #333; */
    outline: none;
    display: flex;
}
.captcha-box img {
    width: 100px;
    height: 40px;
}
.captcha-box button {
    border-radius: 0;
    background: inherit;
    border: none;
    line-height: 1;
    padding: 0px 5px;
}
.captcha-box button i {
    text-align: center;
    line-height: 1;
    color: rgb(255, 81, 0);
    font-size: 20px;
}
.captcha-box input {
    width: 100%;
    background: #eee;
    border: none;
    outline: none;
    line-height: 1;
    font-weight: 600;
    font-size: 18px;
    color: #333;
    padding: 0 15px;
}
.captcha-box input::placeholder {
    color: #aaa;
    font-weight: 500;
}
.form-layout-button {
    border-radius: 20px;
    border: 1px solid #5995fd;
    background: #5995fd;
    color: #fff;
    font-size: 12px;
    font-weight: bold;
    padding: 12px 45px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: transform 80ms ease-in;
}
.form-layout-button:active{
    transform: scale(.95);
}
.form-layout-button:focus{
    outline: none;
}
.form-layout-button.ghost{
    background: transparent;
    border-color: #fff;
}
.container.sign-in-container, .container.sign-up-container {
    display: none;
}
.container.signin-panel-active .sign-in-container {
    display: block;
}
.container.signup-panel-active .sign-in-container {
    display: none;
}
.container.signin-panel-active .sign-up-container {
    display: none;
}
.container.signup-panel-active .sign-up-container {
    display: block;
}
.switch-container {
    position: relative;
    height: 58px;
}
.switch-container .switch-layout-box {
    width: 50%;
    height: 100%;
    display: inline-block;
}
.switch-layout-overlay {
    position: absolute;
    top: 0;
    left: 50%;
    z-index: 99;
    width: 0;
    height: 58px;
    border-right: 1px solid #fff;
}
.switch-layout-box .switch-layout-button{
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    cursor: pointer;
    text-align: center;
    font-size: 18px;
    line-height: 58px;
    color: #555;
}
.switch-layout-box.selected .switch-layout-button{
   background-color: #fff;
   color: #3b78dd;
   border-bottom: 2px solid #3b78dd; 
}